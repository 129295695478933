@font-face {
  font-family: Montserrat-normal;
  src: url("Montserrat-Medium.f3927f88.woff") format("woff2");
}

@font-face {
  font-family: Montserrat-bold;
  src: url("Montserrat-Bold.829ed0b9.woff") format("woff2");
}

@font-face {
  font-family: Montserrat-black;
  src: url("Montserrat-Black.a0e01140.woff") format("woff2");
}

body {
  font-family: Montserrat-normal, sans-serif;
  font-size: 100%;
}

h1, h2 {
  font-family: Montserrat-bold, sans-serif;
  font-size: 1.5rem;
}

p {
  font-family: Montserrat-normal, sans-serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

small {
  font-family: Montserrat-normal, sans-serif;
  font-size: 1rem;
}
/*# sourceMappingURL=index.418074bb.css.map */
