@font-face {
    font-family: 'Montserrat-normal';
    /*src: url('../Fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');*/
    src: url('../Fonts/Montserrat/Montserrat-Medium.woff') format('woff2');
}

@font-face {
    font-family: 'Montserrat-bold';
    /*src: url('../Fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');*/
    src: url('../Fonts/Montserrat/Montserrat-Bold.woff') format('woff2');
}

@font-face {
    font-family: 'Montserrat-black';
    /*src: url('../Fonts/Montserrat/Montserrat-Black.ttf') format('truetype');*/
    src: url('../Fonts/Montserrat/Montserrat-Black.woff') format('woff2');
}

body {
    font-size: 100%;
    font-family: Montserrat-normal, sans-serif;
}

h1 {
    font-family: Montserrat-bold, sans-serif;
    font-size: 1.5rem;
}

h2 {
    font-family: Montserrat-bold, sans-serif;
    font-size: 1.5rem;
}

p {
    font-family: Montserrat-normal, sans-serif;
    font-size: 1.25rem;
    line-height: 1.5rem;
}

small {
    font-family: Montserrat-normal, sans-serif;
    font-size: 1rem;
}
